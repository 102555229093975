export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_CRASHES = "SET_CRASHES";
export const CLEAR_CRASHES = "CLEAR_CRASHES";

export const SHOW_OTP_INPUT = 'SHOW_OTP_INPUT';
export const LOG_IN = "LOG_IN";
export const REGISTER = "REGISTER";
export const LOG_OUT = "LOG_OUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const OTP_VERIFIED = "OTP_VERIFIED";

export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';

export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

export const ADD_CUSTOMER_REQUEST = 'ADD_CUSTOMER_REQUEST';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILURE = 'ADD_CUSTOMER_FAILURE';

export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';

export const FETCH_ORDER_COUNT_REQUEST = 'FETCH_ORDER_COUNT_REQUEST';
export const FETCH_ORDER_COUNT_SUCCESS = 'FETCH_ORDER_COUNT_SUCCESS';
export const FETCH_ORDER_COUNT_FAILURE = 'FETCH_ORDER_COUNT_FAILURE';

export const FETCH_CARD_GROUP_REQUEST = 'FETCH_CARD_GROUP_REQUEST';
export const FETCH_CARD_GROUP_SUCCESS = 'FETCH_CARD_GROUP_SUCCESS';
export const FETCH_CARD_GROUP_FAILURE = 'FETCH_CARD_GROUP_FAILURE';

export const CREATE_CARD_GROUP_REQUEST = 'CREATE_CARD_GROUP_REQUEST';
export const CREATE_CARD_GROUP_SUCCESS = 'CREATE_CARD_GROUP_SUCCESS';
export const CREATE_CARD_GROUP_FAILURE = 'CREATE_CARD_GROUP_FAILURE';

export const EDIT_CARD_GROUP_REQUEST = 'EDIT_CARD_GROUP_REQUEST';
export const EDIT_CARD_GROUP_SUCCESS = 'EDIT_CARD_GROUP_SUCCESS';
export const EDIT_CARD_GROUP_FAILURE = 'EDIT_CARD_GROUP_FAILURE';

export const DELETE_CARD_GROUP_REQUEST = 'DELETE_CARD_GROUP_REQUEST';
export const DELETE_CARD_GROUP_SUCCESS = 'DELETE_CARD_GROUP_SUCCESS';
export const DELETE_CARD_GROUP_FAILURE = 'DELETE_CARD_GROUP_FAILURE';

export const FETCH_CARDS_REQUEST = 'FETCH_CARDS_REQUEST';
export const FETCH_CARDS_SUCCESS = 'FETCH_CARDS_SUCCESS';
export const FETCH_CARDS_FAILURE = 'FETCH_CARDS_FAILURE';

export const CREATE_CARDS_REQUEST = 'CREATE_CARDS_REQUEST';
export const CREATE_CARDS_SUCCESS = 'CREATE_CARDS_SUCCESS';
export const CREATE_CARDS_FAILURE = 'CREATE_CARDS_FAILURE';

export const UPDATE_CARDS_REQUEST = 'UPDATE_CARDS_REQUEST';
export const UPDATE_CARDS_SUCCESS = 'UPDATE_CARDS_SUCCESS';
export const UPDATE_CARDS_FAILURE = 'UPDATE_CARDS_FAILURE';

export const DELETE_CARDS_REQUEST = 'DELETE_CARDS_REQUEST';
export const DELETE_CARDS_SUCCESS = 'DELETE_CARDS_SUCCESS';
export const DELETE_CARDS_FAILURE = 'DELETE_CARDS_FAILURE';

export const FETCH_CARD_SAMPLE_REQUEST = 'FETCH_CARD_SAMPLE_REQUEST';
export const FETCH_CARD_SAMPLE_SUCCESS = 'FETCH_CARD_SAMPLE_SUCCESS';
export const FETCH_CARD_SAMPLE_FAILURE = 'FETCH_CARD_SAMPLE_FAILURE';

export const CREATE_CARD_SAMPLE_REQUEST = 'CREATE_CARD_SAMPLE_REQUEST';
export const CREATE_CARD_SAMPLE_SUCCESS = 'CREATE_CARD_SAMPLE_SUCCESS';
export const CREATE_CARD_SAMPLE_FAILURE = 'CREATE_CARD_SAMPLE_FAILURE';

export const UPDATE_CARD_SAMPLE_REQUEST = 'UPDATE_CARD_SAMPLE_REQUEST';
export const UPDATE_CARD_SAMPLE_SUCCESS = 'UPDATE_CARD_SAMPLE_SUCCESS';
export const UPDATE_CARD_SAMPLE_FAILURE = 'UPDATE_CARD_SAMPLE_FAILURE';

export const DELETE_CARD_SAMPLE_REQUEST = 'DELETE_CARD_SAMPLE_REQUEST';
export const DELETE_CARD_SAMPLE_SUCCESS = 'DELETE_CARD_SAMPLE_SUCCESS';
export const DELETE_CARD_SAMPLE_FAILURE = 'DELETE_CARD_SAMPLE_FAILURE';

export const GET_SINGLE_CARD_SAMPLE_REQUEST = 'GET_SINGLE_CARD_SAMPLE_REQUEST'
export const GET_SINGLE_CARD_SAMPLE_SUCCESS = 'GET_SINGLE_CARD_SAMPLE_SUCCESS'
export const GET_SINGLE_CARD_SAMPLE_FAILURE = 'GET_SINGLE_CARD_SAMPLE_FAILURE'

export const FETCH_CARD_FROM_FIELD_REQUEST = 'FETCH_CARD_FROM_FIELD_REQUEST';
export const FETCH_CARD_FROM_FIELD_SUCCESS = 'FETCH_CARD_FROM_FIELD_SUCCESS';
export const FETCH_CARD_FROM_FIELD_FAILURE = 'FETCH_CARD_FROM_FIELD_FAILURE';

export const CREATE_CARD_FROM_FIELD_REQUEST = 'CREATE_CARD_FROM_FIELD_REQUEST';
export const CREATE_CARD_FROM_FIELD_SUCCESS = 'CREATE_CARD_FROM_FIELD_SUCCESS';
export const CREATE_CARD_FROM_FIELD_FAILURE = 'CREATE_CARD_FROM_FIELD_FAILURE';

export const UPDATE_CARD_FROM_FIELD_REQUEST = 'UPDATE_CARD_FROM_FIELD_REQUEST';
export const UPDATE_CARD_FROM_FIELD_SUCCESS = 'UPDATE_CARD_FROM_FIELD_SUCCESS';
export const UPDATE_CARD_FROM_FIELD_FAILURE = 'UPDATE_CARD_FROM_FIELD_FAILURE';

export const DELETE_CARD_FROM_FIELD_REQUEST = 'DELETE_CARD_FROM_FIELD_REQUEST';
export const DELETE_CARD_FROM_FIELD_SUCCESS = 'DELETE_CARD_FROM_FIELD_SUCCESS';
export const DELETE_CARD_FROM_FIELD_FAILURE = 'DELETE_CARD_FROM_FIELD_FAILURE';

export const FETCH_CARD_FROM_FIELD_OPTIONS_REQUEST = 'FETCH_CARD_FROM_FIELD_OPTIONS_REQUEST';
export const FETCH_CARD_FROM_FIELD_OPTIONS_SUCCESS = 'FETCH_CARD_FROM_FIELD_OPTIONS_SUCCESS';
export const FETCH_CARD_FROM_FIELD_OPTIONS_FAILURE = 'FETCH_CARD_FROM_FIELD_OPTIONS_FAILURE';

export const CREATE_CARD_FROM_FIELD_OPTIONS_REQUEST = 'CREATE_CARD_FROM_FIELD_OPTIONS_REQUEST';
export const CREATE_CARD_FROM_FIELD_OPTIONS_SUCCESS = 'CREATE_CARD_FROM_FIELD_OPTIONS_SUCCESS';
export const CREATE_CARD_FROM_FIELD_OPTIONS_FAILURE = 'CREATE_CARD_FROM_FIELD_OPTIONS_FAILURE';

export const UPDATE_CARD_FROM_FIELD_OPTIONS_REQUEST = 'UPDATE_CARD_FROM_FIELD_OPTIONS_REQUEST';
export const UPDATE_CARD_FROM_FIELD_OPTIONS_SUCCESS = 'UPDATE_CARD_FROM_FIELD_OPTIONS_SUCCESS';
export const UPDATE_CARD_FROM_FIELD_OPTIONS_FAILURE = 'UPDATE_CARD_FROM_FIELD_OPTIONS_FAILURE';

export const DELETE_CARD_FROM_FIELD_OPTIONS_REQUEST = 'DELETE_CARD_FROM_FIELD_OPTIONS_REQUEST';
export const DELETE_CARD_FROM_FIELD_OPTIONS_SUCCESS = 'DELETE_CARD_FROM_FIELD_OPTIONS_SUCCESS';
export const DELETE_CARD_FROM_FIELD_OPTIONS_FAILURE = 'DELETE_CARD_FROM_FIELD_OPTIONS_FAILURE';

export const FETCH_CARD_ENTRY_REQUEST = 'FETCH_CARD_ENTRY_REQUEST';
export const FETCH_CARD_ENTRY_SUCCESS = 'FETCH_CARD_ENTRY_SUCCESS';
export const FETCH_CARD_ENTRY_FAILURE = 'FETCH_CARD_ENTRY_FAILURE';

export const CREATE_CARD_ENTRY_REQUEST = 'CREATE_CARD_ENTRY_REQUEST';
export const CREATE_CARD_ENTRY_SUCCESS = 'CREATE_CARD_ENTRY_SUCCESS';
export const CREATE_CARD_ENTRY_FAILURE = 'CREATE_CARD_ENTRY_FAILURE';

export const UPDATE_CARD_ENTRY_REQUEST = 'UPDATE_CARD_ENTRY_REQUEST';
export const UPDATE_CARD_ENTRY_SUCCESS = 'UPDATE_CARD_ENTRY_SUCCESS';
export const UPDATE_CARD_ENTRY_FAILURE = 'UPDATE_CARD_ENTRY_FAILURE';

export const DELETE_CARD_ENTRY_REQUEST = 'DELETE_CARD_ENTRY_REQUEST';
export const DELETE_CARD_ENTRY_SUCCESS = 'DELETE_CARD_ENTRY_SUCCESS';
export const DELETE_CARD_ENTRY_FAILURE = 'DELETE_CARD_ENTRY_FAILURE';

export const FETCH_CARD_ENTRY_VALUES_REQUEST = 'FETCH_CARD_ENTRY_VALUES_REQUEST';
export const FETCH_CARD_ENTRY_VALUES_SUCCESS = 'FETCH_CARD_ENTRY_VALUES_SUCCESS';
export const FETCH_CARD_ENTRY_VALUES_FAILURE = 'FETCH_CARD_ENTRY_VALUES_FAILURE';

export const CREATE_CARD_ENTRY_VALUES_REQUEST = 'CREATE_CARD_ENTRY_VALUES_REQUEST';
export const CREATE_CARD_ENTRY_VALUES_SUCCESS = 'CREATE_CARD_ENTRY_VALUES_SUCCESS';
export const CREATE_CARD_ENTRY_VALUES_FAILURE = 'CREATE_CARD_ENTRY_VALUES_FAILURE';

export const UPDATE_CARD_ENTRY_VALUES_REQUEST = 'UPDATE_CARD_ENTRY_VALUES_REQUEST';
export const UPDATE_CARD_ENTRY_VALUES_SUCCESS = 'UPDATE_CARD_ENTRY_VALUES_SUCCESS';
export const UPDATE_CARD_ENTRY_VALUES_FAILURE = 'UPDATE_CARD_ENTRY_VALUES_FAILURE';

export const DELETE_CARD_ENTRY_VALUES_REQUEST = 'DELETE_CARD_ENTRY_VALUES_REQUEST';
export const DELETE_CARD_ENTRY_VALUES_SUCCESS = 'DELETE_CARD_ENTRY_VALUES_SUCCESS';
export const DELETE_CARD_ENTRY_VALUES_FAILURE = 'DELETE_CARD_ENTRY_VALUES_FAILURE';

export const FETCH_DOWNLOAD_REQUEST = 'FETCH_DOWNLOAD_REQUEST';
export const FETCH_DOWNLOAD_SUCCESS = 'FETCH_DOWNLOAD_SUCCESS';
export const FETCH_DOWNLOAD_FAILURE = 'FETCH_DOWNLOAD_FAILURE';

export const FETCH_TEMPLATE_RECORD_REQUEST = 'FETCH_TEMPLATE_RECORD_REQUEST';
export const FETCH_TEMPLATE_RECORD_SUCCESS ='FETCH_TEMPLATE_RECORD_SUCCESS';
export const FETCH_TEMPLATE_RECORD_FAILURE = 'FETCH_TEMPLATE_RECORD_FAILURE';

export const SEND_TEMPLATE_ID_REQUEST = 'SEND_TEMPLATE_ID_REQUEST';
export const SEND_TEMPLATE_ID_SUCCESS = 'SEND_TEMPLATE_ID_SUCCESS';
export const SEND_TEMPLATE_ID_FAILURE = 'SEND_TEMPLATE_ID_FAILURE';
















