import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, Menu, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography, InputAdornment, Pagination } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchCardsData } from '../../redux/actions/cardsActions';
import { createCardEntyrData, deleteCardEntryData, fetchCardEntryData, updateCardEntryData } from '../../redux/actions/cardEntryActions';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

function CradEntry() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cards = useSelector((state) => state?.cards?.cardsData?.data);
    const cardEntry = useSelector((state) => state?.cardEntry?.cardEntryData?.data);
    console.log("entry data", cardEntry);
    const [loading, setLoading] = useState(true);

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditEntryId(id);
        setDeleteEntryeId(id);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setEditEntryId(null);
        setDeleteEntryeId(null);
    };

    // pagination 

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const limit = 50;

    useEffect(() => {
        fetchCardEntryData(page, limit);
    }, [page]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        await setPage(value);
        let rsp = await dispatch(fetchCardEntryData(value, limit));
    };

    const handleSearch = async () => {
        setPage(1);
        setLoading(true);
        await dispatch(fetchCardEntryData(1, limit, search));
        setLoading(false);
    };



    // Fetch card Entry data on component mount
    useEffect(() => {
        const fetchCardEntry = async () => {
            setLoading(true);
            await dispatch(fetchCardsData());
            let rsp = await dispatch(fetchCardEntryData(page, limit, search));
            let total = rsp?.data?.total;
            let pages = Math.ceil(total / Number(limit));
            setTotalPages(pages);
            setLoading(false);
        };

        fetchCardEntry();
    }, [dispatch]);

    const columns = [
        { id: 'entryId', name: 'Id' },
        { id: 'userid', name: 'User id' },
        { id: 'title', name: 'Title' },
        { id: 'status', name: 'Status' },
        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [cardId, setCardId] = useState('');
    const [title, setTitle] = useState('');
    const [userId, setUserid] = useState('');



    const functionAdd = () => {
        openPopup();
    };

    const closePopup = () => {
        setOpen(false);
    };

    const openPopup = () => {
        setOpen(true);
    };

    const handleCardEntrySubmit = async (e) => {
        e.preventDefault();
        const newCards = {
            card_id: cardId, user_id: userId, title
        }
        const response = await dispatch(createCardEntyrData(newCards));
        let data = await dispatch(fetchCardEntryData());
        closePopup();
        setCardId();
        setTitle();
        setUserid();
    }

    // Edit functionality
    const [editOpen, setEditOpen] = useState(false);
    const [editEntryId, setEditEntryId] = useState('');
    const [editCardId, setEditCardId] = useState('');
    const [editUserId, setEditUserId] = useState('');
    const [editTitle, setEditTitle] = useState('');

    const functionEdit = useCallback((card) => {
        console.log("inside Edit", card);
        setEditEntryId(card.id);
        setEditCardId(card.card_id);
        setEditUserId(card.user_id);
        setEditTitle(card.title);

        setEditOpen(true);
    }, []);

    const closeEditPopup = () => {
        setEditOpen(false);
    };

    const handleCardEntryEditSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: editEntryId, card_id: editCardId, user_id: editUserId, title: editTitle
        }
        const response = await dispatch(updateCardEntryData(updatedCards))
        await dispatch(fetchCardEntryData());
        closeEditPopup();
    };



    // Delete functionality
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteEntryId, setDeleteEntryeId] = useState('');

    const functionDelete = useCallback((id) => {
        setDeleteEntryeId(id);
        setDeleteOpen(true);
    }, []);
    const closeDeletePopup = () => {
        setDeleteOpen(false);
    };
    const handleCardEntryDelete = async () => {
        if (deleteEntryId) {
            const response = await dispatch(deleteCardEntryData(deleteEntryId));
            await dispatch(fetchCardEntryData());
            closeDeletePopup();
        }
    };

    return (
        <>
            <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
                <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: { xs: 'flex-start', md: 'space-between' }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}>
                            <Typography sx={{
                                fontSize: { xs: '20px', sm: '24px', md: '28px' },
                                mb: { xs: 2, md: 0 }
                            }}>
                                Card Entry
                            </Typography>
                            <TextField
                                size="small"
                                placeholder="Search"
                                sx={{
                                    width: { xs: '100%', sm: '200px' },
                                    borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px'
                                    }
                                }}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleSearch}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            gap: { xs: 1, md: 0.5 },
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: { xs: 'center', md: 'flex-end' },
                            width: { xs: '100%', md: 'auto' },
                            alignItems: 'center'
                        }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: 'midnightblue',
                                    textTransform: 'none',
                                    fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                    width: { xs: '100%', sm: 'auto' }
                                }}
                                onClick={functionAdd}
                            >
                                Add Card Entry
                            </Button>
                        </Box>
                    </Box>
                </Toolbar>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow >
                            {columns.map((column) => (
                                <TableCell key={column.id} >{column.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    Loading...
                                </TableCell>
                            </TableRow>
                        ) : (
                            cardEntry && cardEntry?.rows.map((card) => (
                                <TableRow key={card.id}>
                                    <TableCell>{card.id}</TableCell>
                                    <TableCell>{card.user_id}</TableCell>
                                    <TableCell>{card.title}</TableCell>
                                    <TableCell>{card.status}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={(event) => handleMenuClick(event, card.id)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={menuOpen && editEntryId === card.id && deleteEntryId === card.id}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => functionEdit(card)}>Edit</MenuItem>
                                            <MenuItem onClick={() => functionDelete(card.id)}>Delete</MenuItem>
                                            <MenuItem onClick={() => navigate(`card-entry-values/${card.id}`)}>Card Entry Values</MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
                <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
            </Box>

            {/* Add Dialog */}
            <Dialog open={open} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Create Card Entry</Typography>
                    </DialogTitle>
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCardEntrySubmit}>
                        <Stack spacing={2} margin={2}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Card Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Group Id"
                                    onChange={e => setCardId(e.target.value)}
                                >
                                    {cards && cards?.rows && cards?.rows.length > 0 ? (
                                        cards?.rows.map((card) => (
                                            <MenuItem key={card.id} value={card.id}>{card.card_name}</MenuItem>
                                        ))
                                    ) : (
                                        <Typography variant="body1" color="initial"></Typography>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField variant='outlined' value={userId} onChange={e => setUserid(e.target.value)} label="User Id" />
                            <TextField variant='outlined' value={title} onChange={e => setTitle(e.target.value)} label="Title" />
                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closePopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Card Entry</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCardEntryEditSubmit}>
                        <Stack spacing={2} margin={2}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Card Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Group Id"
                                    value={editCardId}
                                    onChange={e => setEditCardId(e.target.value)}
                                >
                                    {cards && cards?.rows && cards?.rows.length > 0 ? (
                                        cards?.rows.map((card) => (
                                            <MenuItem key={card.id} value={card.id}>{card.card_name}</MenuItem>
                                        ))
                                    ) : (
                                        <Typography variant="body1" color="initial"></Typography>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField required variant='outlined' value={editUserId} onChange={e => setEditUserId(e.target.value)} label="User id" />
                            <TextField variant='outlined' value={editTitle} onChange={e => setEditTitle(e.target.value)} label="Title" />

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog open={deleteOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Delete Card</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDeletePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{ marginTop: 4 }}>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                        Are you sure you want to delete this card group?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={closeDeletePopup}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleCardEntryDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CradEntry;
