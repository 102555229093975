import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTheme, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TopNavigation from './TopNavigation';
import { Typography, Button, useMediaQuery } from '@mui/material';
import Logo from '../../../src/assets/icons/Logo.jpg';
import packageJson from '../../../package.json';
import clearLocalStorage from '../../redux/reducers/authReducers';
import * as actionTypes from "./../../redux/actionTypes";
import Collapse from '@mui/material/Collapse';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: 'white',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function MiniDrawer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();
  const [open, setOpen] = useState(() => !isMobile);
  const [isCollapse, setIsCollapse] = useState(false);

  const handleCollapse = () => {
    setIsCollapse(!isCollapse);
  }

  const [isCommonCollapse, setIsCommonCollapse] = useState(false);

  const handleCommonCollapse = () => {
    setIsCommonCollapse(!isCommonCollapse);
  };

  const [isQuickCollapse, setIsQuickCollapse] = useState(false);

  const handleQuickCollapse = () => {
    setIsQuickCollapse(!isQuickCollapse);
  }



  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);
  const appVersion = packageJson.version;

  const [showVersion, setShowVersion] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    // Add your logout logic here
    console.log('Logged out');
    await clearLocalStorage({
      isAuthenticated: false
    }, {
      type: actionTypes.LOG_OUT
    });
    navigate('/auth'); // redirect to login page after logout
  };

  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en';
  });
  const dashboard = {
    en: 'Dashboard',
    hi: 'डैशबोर्ड'
  }
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{
            justifyContent: 'space-between',
            ...(open && {
              paddingRight: '240px',
            }),
          }}>
            <IconButton
              color="gray"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
              {!open && (
                <Typography variant="h6" noWrap component="div" sx={{ paddingLeft: 0, color: '#003366', fontWeight: 'bold' }}>
                  <img src={Logo} height={'50px'} alt="Logo" />
                </Typography>
              )}
            </IconButton>
            <TopNavigation open={open} />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <Typography variant="h6" noWrap component="div" sx={{ paddingLeft: 0, color: '#003366', fontWeight: 'bold' }}>
              {open && <img src={Logo} height={'50px'} alt="Logo" />}
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {[
              { text: dashboard[language], icon: <DashboardIcon />, route: '/dashboard' },

            ].map((item) => (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: 'block' }}
                onClick={() => navigate(item.route)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,

                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>

              </ListItem>
            ))}
            <ListItem disablePadding sx={{ display: 'block' }} onClick={handleCollapse}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 4.5,
                  backgroundColor: '#9e9e9e',
                  mt: 0.2
                }}
              >

                <ListItemText primary="Digital Card" sx={{ opacity: open ? 1 : 0 }} />
                {isCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={isCollapse} timeout="auto" onmounntOnExit sx={{ backgroundColor: "lightgray" }}>
              {[
                { text: 'Card Group', icon: <ArrowForwardIosIcon />, route: '/cards/card-group' },
                { text: 'Card', icon: <ArrowForwardIosIcon />, route: '/cards/card' },
                { text: 'Card Entry', icon: <ArrowForwardIosIcon />, route: '/cards/card-entry' },

              ].map((item) => (
                <ListItem
                  key={item.text}
                  disablePadding
                  sx={{ display: 'block' }}
                  onClick={() => navigate(item.route)}
                >
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: {
                          fontSize: '0.825rem'
                        }
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0, }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem'
                        }
                      }}
                    />
                  </ListItemButton>

                </ListItem>
              ))}
            </Collapse>
            <ListItem disablePadding sx={{ display: 'block' }} onClick={handleCommonCollapse}>
              <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 4.5, backgroundColor: '#9e9e9e', mt: 0.2 }}>

                <ListItemText primary="Common" sx={{ opacity: open ? 1 : 0 }} />
                {isCommonCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={isCommonCollapse} timeout="auto" unmountOnExit sx={{ backgroundColor: 'lightgray' }}>
              {[
                { text: 'Download', icon: <ArrowForwardIosIcon />, route: '/common/download' },
              ].map((item) => (
                <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={() => navigate(item.route)}>
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: {
                          fontSize: '0.825rem'
                        }
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0, }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem'
                        }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>
            <ListItem disablePadding sx={{ display: 'block' }} onClick={handleQuickCollapse}>
              <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 4.5, backgroundColor: '#9e9e9e', mt: 0.2 }}>

                <ListItemText primary="Quick Uploader" sx={{ opacity: open ? 1 : 0 }} />
                {isQuickCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse in={isQuickCollapse} timeout="auto" unmountOnExit sx={{ backgroundColor: 'lightgray' }}>
              {[
                { text: 'Simple Design Upload(Only One Image)', icon: <ArrowForwardIosIcon />, route: '/quick-uploader/direct-create' },
                { text: 'Foreground and Background Image Upload', icon: <ArrowForwardIosIcon />, route: '/quick-uploader/bg-fg-create' },
                { text: 'Political Image Upload(Only One Image)', icon: <ArrowForwardIosIcon />, route: '/quick-uploader/political-direct-create' },
              ].map((item) => (
                <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={() => navigate(item.route)}>
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                      {React.cloneElement(item.icon, {
                        sx: {
                          fontSize: '0.825rem'
                        }
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0, }}
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: '0.825rem'
                        }
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>

          </List>

          <Box sx={{ flexGrow: 1 }} /> {/* Spacer to push the logout button to the bottom */}
          <Divider />
          {open && (
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Typography variant="body2" color="textSecondary">
                Version {appVersion}
              </Typography>
              <Button
                variant="contained"
                onClick={handleLogout}
                sx={{
                  mt: 1,
                  width: '100%',
                  textTransform: 'none',
                  fontSize: '1rem',
                  borderRadius: 4,
                  backgroundColor: '#555555',
                }}
              >
                Logout
              </Button>
            </Box>
          )}

        </Drawer>
      </Box>
    </>
  );
}
