import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from "../components/navigation/Navigation";
import PublicRoutes from './publicRoutes';
import ProtectedRoutes from './protectedRoutes';
import AuthPage from '../view/authPage/AuthPage';
import Dashboard from '../view/dashboard/Dashboard';
import CardGroup from '../view/cardGroup/CardGroup';
import Cards from '../view/card/Cards';
import CardSample from '../view/cardSample/CardSample';
import CardFormField from '../view/card form field/CardFormField';
import CardFromFieldOption from '../view/card form field options/CardFormFieldOption';
import CardEntry from '../view/card entry/CardEntry';
import CardEntryValues from '../view/card entry values/CardEntryValues';
import { Card } from '@mui/material';
import CardFormFieldOption from '../view/card form field options/CardFormFieldOption';
import Downloads from '../view/common/Downloads';
import SimpleDesignUpload from '../view/quick-uploader/SimpleDesignUpload';
import ForegroundBackgroundImageUpload from '../view/quick-uploader/ForegroundBackgroundImageUpload';
import PoliticalUpload from '../view/quick-uploader/PoliticalUpload';



const MainRouter = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path='/auth' element={<AuthPage />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path='/' element={<Navigation />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/cards" >
                {/* Child routes for cards */}
                <Route path="card-group" element={<CardGroup />} />
                <Route path="card">
                  <Route path='' element={<Cards/>}/>
                  <Route path="card-sample/:card_id" element={<CardSample />} />
                  <Route path="card-form-field/:card_id" >
                    <Route path=''element={<CardFormField />}/>
                    <Route path='field-options/:child' element={<CardFormFieldOption/>}/>
                    <Route path='field-options/:id' element={<CardFormFieldOption/>}/>
                  </Route>
                  <Route path='card-sample/:id' element={<CardSample />} />
                  <Route path='card-form-field/:id' element={<CardFormField/>}/> 
                </Route>


                <Route path="card-entry"  >
                  <Route path='' element={<CardEntry />}/>
                  <Route path='card-entry-values/:child'  element={<CardEntryValues />}/>
                  <Route path='card-entry-values/:id'  element={<CardEntryValues />}/>
                </Route>
              </Route>
              <Route path="/common">
                <Route path="download" element={<Downloads/>}/>
              </Route>
              <Route path="/quick-uploader">
                <Route path="direct-create" element={<SimpleDesignUpload/>}/>
                <Route path="bg-fg-create" element={<ForegroundBackgroundImageUpload/>}/>
                <Route path="political-direct-create" element={<PoliticalUpload/>}/>
              </Route>

            </Route>
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default MainRouter;