import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import AuthReducer from "./authReducers";
import orderCountReducer from "./orderCountReducers";
import cardGroupReducer from "./cardGroupReducer";
import cardsReducer from "./cardsReducers";
import cardSampleReducer from "./cardSampleReducers";
import cardFromFieldReducer from "./cardFromFieldReducer";
import cardFromFieldOptionsReducer from "./cardFromFieldOptionReducers";
import cardEntryreducer from "./cardEntryReducers";
import cardEntryValueReducer from "./cardEntryValuesReducer";
import downloadReducer from "./downloadReducer";
import templateRecordReducer from "./quickUploaderReducers";

const persistConfig = {
    key: "root",
    storage,
};


const rootReducer = combineReducers({
    auth: AuthReducer,
    orderCount: orderCountReducer,
    cardGroup: cardGroupReducer,
    cards: cardsReducer,
    cardSample: cardSampleReducer,
    cardFromField: cardFromFieldReducer,
    cardFromFieldOptions: cardFromFieldOptionsReducer,
    cardEntry: cardEntryreducer,
    cardEntryValue: cardEntryValueReducer,
    download: downloadReducer,
    templateRecord: templateRecordReducer,

});

export default persistReducer(persistConfig, rootReducer);